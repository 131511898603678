<template>
  <v-container fluid>
    <ProgressCircular :isLoaded="isLoaded" />
    <v-card v-if="isLoaded">
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>
          {{ getItemLocaleData(additionalInfoEvent.name) }} ({{ additionalInfoEvent.event_code }}),
          {{ getItemLocaleData(additionalInfoSelectedEventType.name) }} ({{ additionalInfoSelectedEventType.code }})
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка {{ additionalInfoEvent.packet_event_type | getPackageDesc2 }}</div>
      </v-toolbar>

      <v-card flat color="blue-grey lighten-5">
        <Event
          :additionalInfoEvent="additionalInfoEvent"
          :additionalInfoSelectedEventType="additionalInfoSelectedEventType"
        ></Event>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import ConvertPackagesTypes from '@/mixins/convertPackagesTypes'
import BackButton from '@/components/common/BackButton.vue'
import getLocaleData from '@/mixins/getLocaleData'

export default {
  name: 'InfoEventCard',

  mixins: [ConvertPackagesTypes, getLocaleData],

  components: {
    BackButton,
    ProgressCircular: () => import('@/components/common/ProgressCircular'),
    Event: () => import('@/components/support/info_constructor/events_statuses/LocaleCard'),
  },

  data() {
    return {
      ROUTER_LINKS: ROUTER_LINKS,
      isLoaded: false,
    }
  },

  computed: {
    ...mapState('infoConstructor', ['additionalInfoEvent', 'additionalInfoSelectedEventType']),

    currentCompanyId() {
      return this.$route.params.id
    },
    eventId() {
      return this.$route.params.eventId
    },
    eventTypeId() {
      return this.$route.params.eventTypeId
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {},

  methods: {
    ...mapActions('infoConstructor', ['loadSelectedAdditionalInfoEvent', 'loadSelectedAdditionalInfoEventType']),

    async initialize() {
      if (this.currentCompanyId !== 'undefined') {
        await this.loadSelectedAdditionalInfoEvent(this.eventId)
        await this.loadSelectedAdditionalInfoEventType(this.additionalInfoEvent.event_type.id)
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
  },
}
</script>

<style scoped></style>
